<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 发送工会 -->
					<el-form-item label="发送工会" prop="choose">
						<div class="aaaa">
							<el-input v-model="form.choose" readonly placeholder="请选择发送工会"
								@click="visible = true"></el-input>
						</div>
					</el-form-item>
					<!-- 功能类型 -->
					<el-form-item label="功能类型" prop="genre">
						<el-select v-model="form.genre" placeholder="请选择功能类型">
							<el-option label="我为群众办实事" value="1"
								style="width:100%; text-align-last: center;"></el-option>
							<el-option label="健步走" value="2" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="积分" value="3" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="通知公告" value="4" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="日常活动通知" value="5"
								style="width:100%; text-align-last: center;"></el-option>
							<el-option label="其他短信通知" value="6"
								style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 短信模板 -->
					<el-form-item label="短信模板" prop="remark">
						<div class="aaaa">
							<el-input type="textarea" v-model="form.remark" placeholder="请填写短信模板"></el-input>
						</div>
					</el-form-item>
					<!-- 短信变量 -->
					<el-form-item label="短信变量" prop="remark">
						<div class="aaaa">
							<el-input type="textarea" v-model="form.remark" placeholder="请填写短信变量"></el-input>
						</div>
						<div class="referrals">
							（提示：多个变量用英文逗号隔开）
						</div>
					</el-form-item>
					<!-- 备注 -->
					<el-form-item label="备注" prop="remark">
						<div class="aaaa">
							<el-input type="textarea" v-model="form.remark" placeholder="请填写备注"></el-input>
						</div>
					</el-form-item>
					<!-- 提示 -->
					<div class="hint">
						<div class="remind">提示：</div>
						<div>
							<div class="become"> 本操作不可撤销，请谨慎操作。</div>
						</div>
					</div>
					<!-- 提交按钮 -->
					<div class="submit">
						<el-form-item>
							<el-button type="primary" :loading="loa" @click="onSubmit">提交</el-button>
						</el-form-item>
					</div>
					<div>
						<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']"
							@yes="yes">
							<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
						</s3-layer>
					</div>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onMounted
	} from "vue";
	import {
		ZClist,
		HFparametersadd
	} from '../../utils/api'
	import {
		ElMessage
	} from "element-plus";
	import qs from 'qs'
	import {
		useRouter
	} from "vue-router";
	export default {
		name: "manualOperation",
		setup() {
			onMounted(() => {
				messge()
			})
			// 获取工会列表信息
			const messge = () => {
				ZClist().then((res) => {
					console.log(res)
					texture.value = res.data.data.manageUnionList
				})
			}
			// 所属工会选择
			const yes = () => {
				form.choose = options.value
				visible.value = false;
			};
			// 获取工会code
			let gaincode = ref('')
			// 树形选择
			let options = ref('')
			let dendrogram = (node) => {
				options.value = node.unionName
				form.choose = options.value
				gaincode.value = node.unionCode
			}
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			let texture = ref([])
			const visible = ref(false);
			// 表单内容
			const ruleFormRef = ref(null)
			const form = reactive({
				name: "",
				genre: "",
				choose: "",
				payment: "",
				remark: ""
			})
			// 表单提交
			const router = useRouter();
			const loa = ref(false)
			const onSubmit = () => {
				// console.log('工会',gaincode.value)
				// console.log('商户类型',form.genre)
				// console.log('微信商户号',form.name)
				// console.log('商户支付KEY',form.payment)
				// console.log('备注',form.remark)
				loa.value = true
				// 表单校验
				ruleFormRef.value.validate((valid) => {
					if (valid == true) {
						let data = {
							"unionCode": gaincode.value,
							"payType": form.genre,
							"mchId": form.name,
							"mchKey": form.payment,
							"remarks": form.remark
						}
						HFparametersadd(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: "提交成功",
									type: "success",
									center: true,
								});
								setTimeout(() => {
									router.push({
										name: 'HFargu',
										params: {
											userId: 1
										}
									});
									gaincode.value = ''
									form.genre = ''
									form.name = ''
									form.payment = ''
									form.remark = ''
									form.choose = ''
									loa.value = false
								}, 500)
							}
						})
						console.log('成功')
					} else if (valid == false) {
						// ElMessage({
						//   message: "提交失败",
						//   type: "error",
						//   center: true,
						// });
						loa.value = false
					}
				})
			}
			// 验证表单提示
			const rules = {
				name: [{
					required: true,
					message: "请填写微信商户号",
					trigger: "blur"
				}, ],
				genre: [{
					required: true,
					message: "请选择商户类型",
					trigger: "blur"
				}, ],
				choose: [{
					required: true,
					message: "请选择所属工会",
					trigger: "change"
				}, ],
				payment: [{
					required: true,
					message: "请填写商户支付KEY",
					trigger: "change"
				}, ],
				remark: [{
					required: true,
					message: "请填写备注",
					trigger: "change"
				}, ],
			}
			return {
				// 提交按钮
				loa,
				// 树形选择内容
				yes,
				visible,
				dendrogram,
				defaultProps,
				texture,
				// 表单内容
				ruleFormRef,
				form,
				// 表单提交按钮
				onSubmit,
				rules
			}
		},
	}
</script>>

<style lang='scss' scoped>
	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	.hint {
		text-align: justify;
		display: flex;
		font-size: 14px;
		margin-left: 75px;
		/* margin-top: -10px; */
		margin-bottom: 20px;
	}

	.remind {
		/* width: 65px; */
		color: red;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
	}

	.become {
		color: red;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 125px !important;
		font-weight: bold;
	}

	::v-deep .submit {
		margin-left: 125px;
	}
</style>